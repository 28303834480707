import React, { useEffect, useState } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from '@mui/material/Collapse';
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from "@mui/material/TextField";
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { visuallyHidden } from '@mui/utils';

import CopyToClipboardButton from "./CopyToClipBoardButton";
import Dropdown from "./Dropdown";
import SubCard from "./SubCard";
import { Chip } from "@mui/material";

const initialUserID = "";
const minAttentionLvl = 0;
const maxAttentionLvl = 2;
const initialAttentionLvl = [minAttentionLvl, maxAttentionLvl];

const statusList = ['Active Chat', 'Pending Response', 'Follow-up Needed', 'Check-In Message', 'Feedback Request', 'Session Completed'].map((c) => ({ label: c, value: c }));

let rows = [];

export default function DataTable() {
    const [data, setData] = useState([]);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('unreadMessages');
    const [showFilters, setShowFilters] = useState(false);
    const [filterUserID, setFilterUserID] = useState(initialUserID);
    const [filterAttentionLvl, setFilterAttentionLvl] = useState(initialAttentionLvl);
    const [appliedFilters, setAppliedFilters] = useState([]);

    const addAppliedFilter = (filter) =>
        setAppliedFilters((current) => {
            if (current.includes(filter)) return current;
            return [...current, filter];
        });

    const removeAppliedFilter = (filter) =>
        setAppliedFilters((current) => current.filter((e) => e !== filter));

    const filtersCount = appliedFilters.length;

    const toggleShowFilters = () => setShowFilters((s) => !s);
    const resetFilters = () => {
        setAppliedFilters([]);
        setData(rows);
        setFilterUserID(initialUserID);
        setFilterAttentionLvl(initialAttentionLvl);
    };

    useEffect(() => {
        getUserList();
    }, []);

    function getUserList() {
        const hash = window.location.hash;
        const jwt = hash.split('#').pop().split('&')[0].split('=')[1];
        fetch(`/user-list`, {
            headers: new Headers({
                'Authorization': 'Bearer ' + jwt,
            })
        }).then(async (res) => {
            if (res.status === 403) {
                const domain = window.location.hostname;
                const loginPageUrl = `https://coaches.auth.eu-west-1.amazoncognito.com/login?client_id=7ioqd4p461lcvgl89dn30b8ca9&response_type=token&scope=email+openid&redirect_uri=https%3A%2F%2F${domain}`;
                //force new login by redirecting to cognito login page
                window.location.href = loginPageUrl;
            } else if (res.url.includes('localhost:3000')) {
                setData(fakeData);
                rows = fakeData;
            } else if (res.status === 200) {
                const body = await res.json();
                setData(body);
                rows = body;
            }
        })
            .catch((error) => {
                console.log(error);
            });
    }

    function contacted(event, userId) {
        const hash = window.location.hash;
        const jwt = hash.split('#').pop().split('&')[0].split('=')[1];

        const body = {
            "userId": userId,
            "timestamp": Date.now(),
            "contactStatus": event.target.value
        }

        fetch(`/user-list`, {
            method: "POST",
            headers: new Headers({
                'Authorization': 'Bearer ' + jwt,
            }),
            body: JSON.stringify(body)
        }).then(async (res) => {
            if (res.status === 200) {
                const body = await res.json();
                setData(body);
                rows = body;
            }
            console.log("Result:", body);
        })
            .catch((error) => {
                console.log(error);
            });
    }

    const Range = ({
        value,
        setter,
        field,
        onChange,
        max,
        min,
        label,
        valueLabelFormatter
    }) => (
        <>
            <Typography id={`${field}-range-slider`} gutterBottom>
                {label}: {value[0]} - {value[1]}
            </Typography>
            <Slider
                value={value}
                onChange={(e, v) => {
                    setter(v);
                    if (onChange) onChange(e);
                }}
                valueLabelDisplay="auto"
                aria-labelledby={`${field}-range-slider`}
                max={max}
                min={min}
                getAriaValueText={valueLabelFormatter}
            />
        </>
    );

    useEffect(() => {
        const updatedRows = rows
            .filter((r) => {
                if (r.headers) return true;
                if (initialUserID !== filterUserID) {
                    addAppliedFilter("User ID");
                    try {
                        const filterUserIdRegEx = new RegExp(filterUserID, "gi");
                        return r.user_id.match(filterUserIdRegEx);
                    } catch {
                        return r.user_id.indexOf(filterUserID) !== -1;
                    }
                }
                removeAppliedFilter("name");
                return true;
            })
            .filter((r) => {
                if (r.headers) return true;

                addAppliedFilter("calories");
                const [min, max] = filterAttentionLvl;
                return r['needsAttention'] >= min && r['needsAttention'] <= max;
            })

        setData(updatedRows);
    }, [
        filterUserID,
        filterAttentionLvl
    ]);

    function descendingComparator(a, b, orderBy) {
        if (typeof a[orderBy] === 'undefined') {
            if (typeof b[orderBy] === 'undefined') {
                return 0;
            }
            return 1;
        } else if (typeof b[orderBy] === 'undefined') {
            return -1;
        }

        switch (typeof b[orderBy]) {
            case "number":
            case "string":
            default:
                if (b[orderBy] < a[orderBy]) {
                    return -1;
                }
                if (b[orderBy] > a[orderBy]) {
                    return 1;
                }
                return 0;
        }
    }

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow class="main-row" key={row.id} sx={{ '& > *': { borderBottom: 'unset', cursor: 'pointer' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Tooltip title={row.user_id}>
                            {(function () {
                                const split = row.user_id.split('-');
                                const lastPartId = split[4].substring(split[4].length - 4, split[4].length);
                                const shortId = `${split[0]}-...-${lastPartId}`
                                return shortId;
                            })()}
                        </Tooltip>
                        <CopyToClipboardButton value={row.user_id} />
                    </TableCell>
                    <TableCell align="center">{new Date(row.last_bat).toLocaleString('pt-PT')}</TableCell>
                    <TableCell align="center">{row.last_activity !== '1970-01-01T00:00:00.000Z' ? new Date(row.last_activity).toLocaleString('pt-PT') : ''}</TableCell>
                    <TableCell align="center">{(function () {
                        if (row.last_activity === '1970-01-01T00:00:00.000Z') {
                            row['last_activity_type'] = '';
                            return row['last_activity_type'];
                        }

                        if (row.canceled_calibrations > 0) {
                            row['last_activity_type'] = 'Canceled calibration';
                        } else {
                            row['last_activity_type'] = `${row.type} exercise`;
                        }
                        return row['last_activity_type'];

                    })()}</TableCell>
                    <TableCell align="center">{row.unreadMessages}</TableCell>
                    <TableCell align="center">{row.userLastMessage}</TableCell>
                    <TableCell align="center">{row.userLastMessageTimestamp ? (function () {
                        let now = new Date(0);
                        now.setUTCMilliseconds(row.userLastMessageTimestamp)
                        return now.toLocaleString('pt-PT');
                    }()) : ''}</TableCell>

                    <TableCell align="center"><Chip style={{ backgroundColor: row.canceled_calibrations > 0 ? '#ffb6b6 ' : row.avg_calibration > 60 ? '#fff700' : '#B9F6CA' }} label={(row.canceled_calibrations > 0 ? '2 - Stuck in calibration' : row.avg_calibration > 60 ? '1 - High avg calibration' : '0 - No problems')}>{(row.canceled_calibrations > 0 ? row['needsAttention'] = 2 : row.avg_calibration > 60 ? row['needsAttention'] = 1 : row['needsAttention'] = 0)}</Chip></TableCell>
                    <TableCell align="center">
                        <Dropdown
                            id="status"
                            value={row.contactStatus}
                            onChange={(e) => contacted(e, row.user_id)}
                            label="Status"
                            options={statusList}
                        />
                        <Typography variant="h10" gutterBottom component="div" align="center">
                            {row.coachLastMessageTimestamp ? (function () {
                                let now = new Date(0);
                                now.setUTCMilliseconds(row.coachLastMessageTimestamp)
                                return now.toLocaleString('pt-PT');
                            }()) : ''}
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow class="collapsed-row">
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 2 }}>
                                <Grid container spacing={6}>
                                    <Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
                                        <SubCard title="Profile" sx={{ borderRadius: '8px', width: '-webkit-fill-available' }}>
                                            <Grid container direction="column" spacing={1} sx={{ width: '100%' }}>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{row.workoutPlan ? row.workoutPlan : 'NaN'}</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Workout Plan</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{row.last_bat_score}</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Last BAT score</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{row.count_exercises}</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Number of Exercises</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{row.bat_count}</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Number of BAT's</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                                    <Grid item xs={12}>
                                                        <Divider />
                                                    </Grid>
                                                </Box>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{row.canceled_calibrations}</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Canceled calibrations</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{row.avg_calibration} seconds</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Avg Calibration</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{Math.round((new Date() - new Date(row.first_exercise_date)) / (1000 * 60 * 60 * 24)) + 1}/56</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Pilot Progress</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{row.active_days}</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Active Days</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </SubCard>
                                    </Grid>
                                    <Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
                                        <SubCard title="Latest Exercise" sx={{ borderRadius: '8px', width: '-webkit-fill-available' }}>
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{new Date(row.last_exercise_timestamp).toLocaleDateString('pt-PT')}</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Date</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{new Date(row.last_exercise_timestamp).toLocaleTimeString('pt-PT')}</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Time</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{row.last_exercise_type}</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Type</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{row.last_exercise_score}</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Score</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                                    <Grid item xs={12}>
                                                        <Divider />
                                                    </Grid>
                                                </Box>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={3} >
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{row.last_exercise_duration} seconds</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Duration</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{row.last_exercise_time_to_calibrate_eeg} seconds</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Time to calibrate EEG</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{row.last_exercise_time_to_calibrate_ppg} seconds</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>Time to calibrate PPG</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                            <Typography variant="subtitle1" fontWeight={'bold'}>{Math.round(row.last_exercise_eeg_calibration_perc * 100, 2)}%</Typography>
                                                            <Typography variant="subtitle2" color={'grey'}>EEG calibration %</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </SubCard>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    const headCells = [
        {
            id: '',
            numeric: false,
            label: '',
        },
        {
            id: 'user_id',
            numeric: false,
            label: 'User ID',
        },
        {
            id: 'last_bat',
            numeric: false,
            label: 'Last BAT',
        },
        {
            id: 'last_activity',
            numeric: false,
            label: 'Last Activity Date',
        },
        {
            id: 'last_activity_type',
            numeric: false,
            label: 'Last Activity',
        },
        {
            id: 'unreadMessages',
            numeric: true,
            label: 'Unread Messages',
        },
        {
            id: 'userLastMessage',
            numeric: false,
            label: 'Last Message',
        },
        {
            id: 'userLastMessageTimestamp',
            numeric: false,
            label: 'Last Message Timestamp',
        },
        {
            id: 'needsAttention',
            numeric: true,
            label: 'Needs Attention',
        },
        {
            id: 'coachLastMessageTimestamp',
            numeric: false,
            label: 'Contacted',
        },
    ];

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            sx={headCell.id === 'user_id' ? { minWidth: '190px' } : { minWidth: '0px' }}
                            key={headCell.id}
                            align='center'
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'desc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <Box m={2}>
            <Button
                onClick={toggleShowFilters}
                variant="contained"
                disableElevation
                endIcon={showFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            >
                {showFilters ? "Hide filters" : "Filter"}
                {filtersCount > 0 ? ` (${filtersCount})` : ""}
            </Button>
            {filtersCount > 0 && (
                <Button ml={2} onClick={resetFilters}>
                    Reset filters
                </Button>
            )}
            {showFilters && (
                <Box py={1}>
                    <Card variant="outlined">
                        <CardHeader
                            titleTypographyProps={{ variant: "h6" }}
                            title="Filter options"
                        />
                        <CardContent>
                            <Grid container spacing={4}>
                                <Grid item container spacing={4}>
                                    <Grid item xs={12} sm={6} lg={3}>
                                        <TextField
                                            value={filterUserID}
                                            onChange={(e) => setFilterUserID(e.target.value)}
                                            label="User ID"
                                            variant="filled"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <Range
                                        label="Attention Lvl"
                                        value={filterAttentionLvl}
                                        setter={setFilterAttentionLvl}
                                        field="needsAttention"
                                        valueLabelFormatter={(v) => `${v} Level`}
                                        max={maxAttentionLvl}
                                        min={minAttentionLvl}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button onClick={resetFilters} size="small">
                                Reset
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
            )}
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 800 }}>
                    <Table stickyHeader aria-label="simple table">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(data, getComparator(order, orderBy)).map((row) => (
                                <Row align='center' key={row.user_id} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>

    );
}

DataTable.defaultProps = {
    title: "No Title"
};


const fakeData = [
    {
        "user_id": "08a36fcd-d7cb-4e3a-b9d4-2a3de76a93b6",
        "last_bat": "2023-06-07T15:49:10.000Z",
        "bat_count": 1,
        "active_days": 102,
        "last_activity": "2023-10-31T18:33:11.000Z",
        "type": "Relaxation",
        "score": 0,
        "duration": 120,
        "avg_calibration": 64,
        "last_exercise_timestamp": "2023-10-31T18:33:11.000Z",
        "count_exercises": 451,
        "last_exercise_type": "Relaxation",
        "last_exercise_score": 0,
        "last_exercise_duration": 120,
        "canceled_calibrations": 0,
        "userLastMessage": "I'm okay, thanks.",
        "userLastMessageTimestamp": "1698413452759",
        "unreadMessages": 0,
        "workoutPlan": null,
        "coachLastMessageTimestamp": "1698771717038",
        "last_activity_type": "Relaxation exercise",
        "needsAttention": 1
    },
    {
        "user_id": "479748d7-f0fa-4790-9a4c-2f129420609a",
        "last_bat": "2023-08-18T11:22:57.000Z",
        "bat_count": 5,
        "active_days": 103,
        "last_activity": "2023-10-19T08:42:12.000Z",
        "type": "Activation",
        "score": 0.871,
        "duration": 48,
        "avg_calibration": 44,
        "last_exercise_timestamp": "2023-10-19T08:42:12.000Z",
        "count_exercises": 391,
        "last_exercise_type": "Activation",
        "last_exercise_score": 0.871,
        "last_exercise_duration": 48,
        "canceled_calibrations": 0,
        "userLastMessage": "hi",
        "userLastMessageTimestamp": "1697534264493",
        "unreadMessages": 0,
        "workoutPlan": null,
        "coachLastMessageTimestamp": "1697792548425",
        "last_activity_type": "Activation exercise",
        "needsAttention": 0
    },
    {
        "user_id": "faf3a8ec-2e52-4284-b6d7-f69e894a47ed",
        "last_bat": "2023-08-16T10:53:18.000Z",
        "bat_count": 2,
        "active_days": 30,
        "last_activity": "2023-11-03T09:40:49.000Z",
        "type": "Activation",
        "score": 0.958,
        "duration": 40,
        "avg_calibration": 44,
        "last_exercise_timestamp": "2023-11-03T09:40:49.000Z",
        "count_exercises": 103,
        "last_exercise_type": "Activation",
        "last_exercise_score": 0.958,
        "last_exercise_duration": 40,
        "canceled_calibrations": 0,
        "userLastMessage": "Olá!",
        "userLastMessageTimestamp": "1697189562609",
        "unreadMessages": 1,
        "workoutPlan": null,
        "coachLastMessageTimestamp": null,
        "last_activity_type": "Activation exercise",
        "needsAttention": 0
    },
    {
        "user_id": "da2a40bf-e5b0-483d-a6e3-9a2d87afba49",
        "last_bat": "2023-07-14T13:32:43.000Z",
        "bat_count": 3,
        "active_days": 2,
        "last_activity": "2023-06-30T15:52:13.000Z",
        "type": "Activation",
        "score": 0.865,
        "duration": 40,
        "avg_calibration": 37,
        "last_exercise_timestamp": "2023-06-30T15:52:13.000Z",
        "count_exercises": 7,
        "last_exercise_type": "Activation",
        "last_exercise_score": 0.865,
        "last_exercise_duration": 40,
        "canceled_calibrations": 0,
        "userLastMessage": "test66",
        "userLastMessageTimestamp": "1696431285458",
        "unreadMessages": 0,
        "workoutPlan": null,
        "coachLastMessageTimestamp": "1697755859396",
        "last_activity_type": "Activation exercise",
        "needsAttention": 0
    },
    {
        "user_id": "64bcea07-514d-4513-b515-dbdca01dbaf0",
        "last_bat": "2023-06-30T08:34:54.000Z",
        "bat_count": 1,
        "active_days": 2,
        "last_activity": "2023-07-03T16:06:05.000Z",
        "type": "Focus",
        "score": 1,
        "duration": 645,
        "avg_calibration": 113,
        "last_exercise_timestamp": "2023-07-03T16:06:05.000Z",
        "count_exercises": 5,
        "last_exercise_type": "Focus",
        "last_exercise_score": 1,
        "last_exercise_duration": 645,
        "canceled_calibrations": 0,
        "unreadMessages": 0,
        "last_activity_type": "Focus exercise",
        "needsAttention": 1
    },
    {
        "user_id": "64bf17e6-a3a7-4c5f-9a3d-a3e17825df0f",
        "last_bat": "2023-06-30T11:02:40.000Z",
        "bat_count": 1,
        "active_days": 1,
        "last_activity": "2023-06-30T11:59:09.000Z",
        "type": "Tutorial",
        "score": 0.537,
        "duration": 120,
        "avg_calibration": 45,
        "last_exercise_timestamp": "2023-06-30T11:59:09.000Z",
        "count_exercises": 1,
        "last_exercise_type": "Tutorial",
        "last_exercise_score": 0.537,
        "last_exercise_duration": 120,
        "canceled_calibrations": 0,
        "unreadMessages": 0,
        "last_activity_type": "Tutorial exercise",
        "needsAttention": 0
    },
    {
        "user_id": "44b1453a-038b-46ad-8eda-aa8ee8c27cb3",
        "last_bat": "2023-07-31T14:29:12.000Z",
        "bat_count": 6,
        "active_days": 116,
        "last_activity": "2023-10-30T18:23:51.000Z",
        "type": "Relaxation",
        "score": 0.661,
        "duration": 142,
        "avg_calibration": 58,
        "last_exercise_timestamp": "2023-10-30T18:23:51.000Z",
        "count_exercises": 645,
        "last_exercise_type": "Relaxation",
        "last_exercise_score": 0.661,
        "last_exercise_duration": 142,
        "canceled_calibrations": 0,
        "userLastMessage": "ohai",
        "userLastMessageTimestamp": "1698155177119",
        "unreadMessages": 0,
        "workoutPlan": "serenity",
        "coachLastMessageTimestamp": "1698155325204",
        "last_activity_type": "Relaxation exercise",
        "needsAttention": 0
    },
    {
        "user_id": "dc9a5f44-39a6-42bc-b683-dd7ea9d006e4",
        "last_bat": "2023-08-01T14:26:47.000Z",
        "bat_count": 1,
        "active_days": 0,
        "last_activity": "1970-01-01T00:00:00.000Z",
        "type": null,
        "score": null,
        "duration": null,
        "avg_calibration": null,
        "last_exercise_timestamp": "1970-01-01T00:00:00.000Z",
        "count_exercises": 0,
        "last_exercise_type": null,
        "last_exercise_score": null,
        "last_exercise_duration": null,
        "canceled_calibrations": 0,
        "unreadMessages": 0,
        "last_activity_type": "",
        "needsAttention": 2
    },
    {
        "user_id": "6e48d184-d32c-4eb5-9865-fc2b87123297",
        "last_bat": "2023-07-14T14:26:32.000Z",
        "bat_count": 2,
        "active_days": 1,
        "last_activity": "2023-07-13T14:27:44.000Z",
        "type": "Activation",
        "score": 0.5,
        "duration": 40,
        "avg_calibration": 36,
        "last_exercise_timestamp": "2023-07-13T14:27:44.000Z",
        "count_exercises": 2,
        "last_exercise_type": "Activation",
        "last_exercise_score": 0.5,
        "last_exercise_duration": 40,
        "canceled_calibrations": 0,
        "unreadMessages": 0,
        "last_activity_type": "Activation exercise",
        "needsAttention": 0
    },
    {
        "user_id": "0fc1206a-2d18-46e1-b95a-455bc9d63a90",
        "last_bat": "2023-07-17T13:19:27.000Z",
        "bat_count": 2,
        "active_days": 2,
        "last_activity": "2023-07-17T13:11:10.000Z",
        "type": "Focus",
        "score": 1,
        "duration": 122,
        "avg_calibration": 31,
        "last_exercise_timestamp": "2023-07-17T13:11:10.000Z",
        "count_exercises": 3,
        "last_exercise_type": "Focus",
        "last_exercise_score": 1,
        "last_exercise_duration": 122,
        "canceled_calibrations": 0,
        "unreadMessages": 0,
        "last_activity_type": "Focus exercise",
        "needsAttention": 0
    },
    {
        "user_id": "31b950fd-0a7c-4f3d-8dec-43b4b5fa7171",
        "last_bat": "2023-07-17T11:29:41.000Z",
        "bat_count": 2,
        "active_days": 1,
        "last_activity": "2023-07-13T14:40:48.000Z",
        "type": "Tutorial",
        "score": 0.856,
        "duration": 120,
        "avg_calibration": 41,
        "last_exercise_timestamp": "2023-07-13T14:40:48.000Z",
        "count_exercises": 1,
        "last_exercise_type": "Tutorial",
        "last_exercise_score": 0.856,
        "last_exercise_duration": 120,
        "canceled_calibrations": 0,
        "unreadMessages": 0,
        "last_activity_type": "Tutorial exercise",
        "needsAttention": 0
    },
    {
        "user_id": "b2fe99dd-d95a-4345-a088-b4f8db7f5bd9",
        "last_bat": "2023-07-18T16:51:03.000Z",
        "bat_count": 1,
        "active_days": 118,
        "last_activity": "2023-11-03T14:03:05.000Z",
        "type": "Meditation",
        "score": 0.735,
        "duration": 420,
        "avg_calibration": 30,
        "last_exercise_timestamp": "2023-11-03T14:03:05.000Z",
        "count_exercises": 460,
        "last_exercise_type": "Meditation",
        "last_exercise_score": 0.735,
        "last_exercise_duration": 420,
        "canceled_calibrations": 0,
        "userLastMessage": "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        "userLastMessageTimestamp": "1696606215409",
        "unreadMessages": 1,
        "workoutPlan": null,
        "coachLastMessageTimestamp": null,
        "last_activity_type": "Meditation exercise",
        "needsAttention": 0
    },
    {
        "user_id": "60af6759-5841-422b-9e5c-85d7e67587bb",
        "last_bat": "2023-07-01T17:16:18.000Z",
        "bat_count": 1,
        "active_days": 1,
        "last_activity": "2023-07-01T17:24:05.000Z",
        "type": "Relaxation",
        "score": 0.191,
        "duration": 120,
        "avg_calibration": 40,
        "last_exercise_timestamp": "2023-07-01T17:24:05.000Z",
        "count_exercises": 2,
        "last_exercise_type": "Relaxation",
        "last_exercise_score": 0.191,
        "last_exercise_duration": 120,
        "canceled_calibrations": 0,
        "unreadMessages": 0,
        "last_activity_type": "Relaxation exercise",
        "needsAttention": 0
    }
];