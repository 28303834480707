import './App.css';
import DataTable from './components/DataTable';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <div id='newText'>Emotai Backoffice</div>
          <DataTable title = "User-List" stickyHeader ></DataTable>
        </div>
      </header>
    </div>
  );
}

export default App;
