import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function Dropdown ({id, value, onChange, label, options}) {
    const forwardID = id ?? `${JSON.stringify(options)}-select`;
    return (
      <FormControl variant="filled" fullWidth>
        <InputLabel id={`${forwardID}-label`}>{label}</InputLabel>
        <Select
          labelId={`${forwardID}-label`}
          fullWidth
          id={forwardID}
          value={value}
          onChange={onChange}
          label={label}
          variant="filled"
        >
          {options.map((option) => {
            const {label: optionLabel, value} = option;
            return (
              <MenuItem key={value} value={value}>
                {optionLabel}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };